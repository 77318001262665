import styled from "styled-components"

import { THomeType } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import CommunalAreaIcon from "src/ui/icons/communal-area.svg"
import PrivateAreaIcon from "src/ui/icons/private-area.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { IRadioGroup, RadioGroup } from "src/ui/RadioGroup/RadioGroup"
import { spacing } from "src/ui/spacing"
import { SVGProps } from "src/utils/tsUtil"

export function HomeGroupBuildingCreateHomeTypeStep({
  selectedHomeType,
  setSelectedHomeType,
  loading,
  error,
}: {
  selectedHomeType: THomeType
  setSelectedHomeType: (homeType: THomeType) => void
  loading: boolean
  error: boolean
}) {
  const { t, langKeys } = useTranslate()

  const options: IRadioGroup<THomeType>["options"] = [
    {
      value: "private",
      render: () => (
        <HomeTypeRadioButton
          Icon={PrivateAreaIcon}
          title={t(langKeys.home_group_building_create_home_private_area_title)}
          description={t(
            langKeys.home_group_building_create_home_private_area_description
          )}
        />
      ),
    },
    {
      value: "communal",
      render: () => (
        <HomeTypeRadioButton
          Icon={CommunalAreaIcon}
          title={t(
            langKeys.home_group_building_create_home_communal_area_title
          )}
          description={t(
            langKeys.home_group_building_create_home_communal_area_description
          )}
        />
      ),
    },
  ]

  if (loading) {
    return (
      <LoadingWrapper>
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
      </LoadingWrapper>
    )
  }

  if (error) {
    return (
      <div>
        <MText variant="heading2" marginBottom={spacing.L}>
          {t(langKeys.home_group_building_create_home_title)}
        </MText>

        <MBanner type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      </div>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.home_group_building_create_home_title)}
      </MText>

      <RadioGroup
        value={selectedHomeType}
        options={options}
        onChange={setSelectedHomeType}
        hideRadio
        wrapWidth={650}
      />
    </div>
  )
}

function HomeTypeRadioButton({
  Icon,
  title,
  description,
}: {
  Icon: React.FC<SVGProps>
  title: string
  description: string
}) {
  return (
    <HomeTypeWrapper>
      <Icon width={24} />
      <div>
        <MText variant="heading3">{title}</MText>
        <MText>{description}</MText>
      </div>
    </HomeTypeWrapper>
  )
}

const HomeTypeWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const LoadingWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

import styled from "styled-components"

import { IconButton } from "@minuthq/meatball-ui-react"

import { BillingSummary } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingSummary"
import { CreateHomeTerms } from "src/components/Homes/CreateHomeWizard/CreateHomeTerms"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import PenIcon from "src/ui/icons/pen-outlined.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupBuildingCreateHomeConfirmationStep({
  homeGroupName,
  floor,
  createHomeEstimate,
  checkedTerms,
  onCheckTerms,
  checkedTermsError,
  hideBillingInformation,
  error,
  onEditClick,
}: {
  homeGroupName: string
  floor: string
  createHomeEstimate?: TFetchCreateHomeEstimateResponse
  checkedTerms: boolean
  onCheckTerms: () => void
  checkedTermsError: boolean
  hideBillingInformation?: boolean
  error?: boolean
  onEditClick: () => void
}) {
  const { t, langKeys } = useTranslate()

  if (!hideBillingInformation && !createHomeEstimate) {
    return (
      <LoadingWrapper>
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
      </LoadingWrapper>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.summary)}
      </MText>

      <Box>
        <MCard border boxShadow={false}>
          <DetailsWrapper>
            <MText variant="subtitle">
              {t(langKeys.create_home_confirmation_home_details_title)}
            </MText>
            <IconButton
              Icon={PenIcon}
              variant="minimal"
              onClick={onEditClick}
            />
          </DetailsWrapper>

          <MText>{homeGroupName}</MText>
          <MText>{floor}</MText>
        </MCard>

        {!hideBillingInformation && createHomeEstimate && (
          <BillingSummary createHomeEstimate={createHomeEstimate} />
        )}
      </Box>

      {!hideBillingInformation && (
        <CreateHomeTerms
          checkedTerms={checkedTerms}
          onCheckTerms={onCheckTerms}
          checkedTermsError={checkedTermsError}
        />
      )}

      {error && (
        <StyledMAlert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </StyledMAlert>
      )}
    </div>
  )
}

const StyledMAlert = styled(MBanner)`
  margin-top: ${spacing.M};
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const LoadingWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

import styled from "styled-components"

import { THomeType } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export const BUILDING_HOME_INFORMATION_FORM =
  "building-create-home-information-form"

export function HomeGroupBuildingCreateHomeInformationStep({
  homeGroupName,
  homeType,
  homeName,
  setHomeName,
  floor,
  setFloor,
  aptNumber,
  setAptNumber,
  onSubmit,
}: {
  homeGroupName: string
  homeType: THomeType
  homeName: string
  setHomeName: (name: string) => void
  floor: string
  setFloor: (floor: string) => void
  aptNumber: string
  setAptNumber: (aptNumber: string) => void
  onSubmit: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {homeType === "private"
          ? t(
              langKeys.home_group_building_create_home_information_private_title,
              {
                name: homeGroupName,
              }
            )
          : t(
              langKeys.home_group_building_create_home_information_communal_title,
              {
                name: homeGroupName,
              }
            )}
      </MText>

      <Form
        id={BUILDING_HOME_INFORMATION_FORM}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <MTextField
          label={t(langKeys.home_name)}
          value={homeName}
          onChange={(value) => setHomeName(value)}
          required
          requiredIndicator
        />

        <MTextField
          label={t(langKeys.home_group_building_create_home_floor)}
          value={floor}
          onChange={(value) => setFloor(value)}
          required
          requiredIndicator
        />

        {homeType === "private" && (
          <MTextField
            label={t(langKeys.home_group_building_create_home_apt_number)}
            value={aptNumber}
            onChange={(value) => setAptNumber(value)}
            required
            requiredIndicator
          />
        )}
      </Form>
    </div>
  )
}

const Form = styled.form`
  display: grid;
  grid-gap: ${spacing.M};
`
